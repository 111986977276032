import * as Sentry from "@sentry/vue";
export default (app, router) => {
  Sentry.init({
    app,
    dsn: "https://e7b9d287a666ce37d41a4394def26960@o4506322837110784.ingest.us.sentry.io/4506945074036736",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    tracingOptions: {
      trackComponents: true
    },
    attachStacktrace: true,
    attachProps: true,
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,
    environment: process.env.NODE_ENV,
    enabled: window.location.hostname !== "localhost"
  });
};
